<template>
  <div class="prodetail">
    
    <div class="content">
      
      <div class="proInfo">
        <div class="proName">{{prdDetail.title}}</div>
        <div class="prodesc">{{prdDetail.short_title}}</div>
       
      </div>
     

      <!--详情或者评价-->
      <div id="prodetail"  style="padding: 10px; background:#fff; margin-top:10px;word-break: break-all;"
        v-html="prdDetail.handbook">

      </div>
	  <div style="height: 50px;"></div>
     
    </div>
    <div class="footer">
      <div class="kf flexcenter" @click="GoKefu">
        <img src="../../public/static/images/custom1.png" style="width: 70%;width: 70%;" />
      </div>
      <div class="home flexcenter" @click="GoHome">
        <img src="../../public/static/images/home.png" style="width: 68%;width: 68%;" />
      </div>
     
      <div class="buy" @click="buy">去购买</div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        prdDetail: '', //商品详情
        buyCount: 1,
        goodid: 0,
        swipeCount: 0,
        swipeIdx: 0,
        goodStatus: 1,
        ImageList: [],
        viewtype: 0,
        commentList: []

      }
    },
    created() {
      var id = this.$route.params.id + "";
      if (id.indexOf("_") > 0) {

        this.goodid = id.split("_")[0];
        let fromuser = id.split("_")[1];
        localStorage.setItem("fromuser", fromuser);
        setTimeout(() => {
          //设置上级用户
          this.$store.dispatch("DECODEINFO").then(u => {
            console.log(u)
            this.$store.dispatch("GET", {
              url: "/api/wx/?action=setinvite&fromuser=" + fromuser + "&uid=" + u.uid
            }).then(result => {

            })

          })




        }, 1000)
      } else {
        this.goodid = id;
      }
      this.getproDetail(this.goodid); //商品详情
	  document.title="使用手册";
    },
    mounted() {
      //浏览一次
      this.$store.dispatch("GET", {
        url: "/api/wx/?action=view&id=" + this.goodid
      }).then(result => {

      })

      this.$nextTick(function() {
        $(window).scrollTop(0)
      })
    },
    methods: {
      goShop() {
        this.$router.push("/index")
      },
      GoKefu() {
        this.$router.push("/message")
      },
      GoHome() {
        this.$router.push("/index")
      },
      //获取商品详情
      getproDetail(id) {
        var self = this;
        this.$store.dispatch("GET", {
          url: "/api/wx/?action=product&id=" + id
        }).then(result => {
          let prdDetail = result.data.data.rows[0];
          

          prdDetail.handbook = decodeURIComponent(prdDetail.handbook)
        
          this.prdDetail = prdDetail
          
         
          this.$nextTick(()=>{
            $("img", "#prodetail").each(function(idx, ele) {
              $(ele).css("width", "100%").css("height", "auto").css("vertical-align", "top").attr("preview","detail")
            })

            setTimeout(()=>{
              this.$previewRefresh()
            },1000)
          })
        

          //分享
          this.$store.dispatch("DECODEINFO").then(u => {
            console.log(u)
            this.$store.dispatch("SHARE", {
              query: {
                shareurl: window.location.href.split('#')[0]
              },
              opt: {
                title: self.prdDetail.title,
                desc: self.prdDetail.title,
                link: window.location.href.split("#")[0] + "#/handbook/" + self.prdDetail.id + "_" + u.uid,
                imgUrl: this.ImageList[0],
                success: function() {}
              }
            }).then(result => {})

          })

        })
      },
     
      //立即购买
      buy() {
      
        this.$router.push("/proDetail/"+this.goodid)
      }
    }
  }
</script>

<style scoped="scoped">
  
  .prodetail {
    height: 100%;
    width: 100%;
    position: relative;
  }

  .content {
    margin-bottom:20px;
  }

  .proimg {
    height: 277px;
    background: #FFFFFF;
  }

  .proInfo {
   padding-bottom: 10px;
    background: #f6fafe;
    padding-left: 10px;
    padding-right: 10px;
  }

  .proName {
    margin-top: 5px;
    padding-top: 5px;
    font-size: 18px;
    color: #000000;
  }

  .prodesc {
    font-size: 14px;
  }

  .proPrice {
    display: flex;
    margin-top: 15px;
  }

  .price_left {
    font-size: 24px;
    color: #d92b2b;
    flex: 1;
  }

  .price_right {
    font-size: 15px;
    color: #7b7b7b;
    margin-top: 8px;
  }

  .tradeInfo {
    margin-top: 10px;
    display: flex;
    font-size: 12px;
    color: #7b7b7b;
    padding-bottom: 10px;
  }

  .trade_right {
    margin-left: 30px;
  }

  .probuyCount {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    height: 58px;
    line-height: 58px;
    background: #f6fafe;
    display: flex;
  }

  .probuy_left {
    font-size: 15px;
    color: #7b7b7b;
    flex: 1;
  }

  .probuy_right {
    margin-right: 10px;
  }

  .probuy_right input {
    width: 50px;
    text-align: center;
    height: 19px;
    background: #f6fafe;
    margin-left: -4px;
    margin-right: -5px;
    border: none;
    border-top: 1px solid #26a1ff;
    border-bottom: 1px solid #26a1ff;
    border-radius: 0;
  }

  .btn {
    width: 21px;
    height: 21px;
    border: 1px solid #26a1ff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #26a1ff;
    color: #FFFFFF;
  }

  .btn1 {
    width: 21px;
    height: 21px;
    border: 1px solid #26a1ff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #26a1ff;
    color: #FFFFFF;
  }

  .footer {
    height: 48px;
    line-height: 48px;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 10;
    background: #f6fafe;
    border-top: 1px solid #c7c7c7;
    display: flex;
    font-size: 18px;
    color: #FFFFFF;
  }

  .kf {
    width: 49px;
  }
  .home {
    width: 49px;
    padding-right: 10px;
  }

  .addcart {
    flex: 1;
    text-align: center;
    background: #ffc7ee;
  }

  .buy {
    flex: 1;
    text-align: center;
    background: #26A1FF;
  }

  .viewtype {
    width: 40%;
    height: 100%;
    float: left;
    margin-left: 5%;
    border-bottom: 2px solid #f6fafe;
    box-sizing: border-box;
    font-size: 18px;
    text-align: center;
  }

  .viewckd {
    border-bottom: 2px solid #26a1ff;
  }
</style>
